/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [  
  {
    url: null,
    name: "asdf",
    userMenu: true,
    slug: "member",
    icon: "UserIcon",
    levelUp: '0',
    levelDw: '12',
    submenu: [
      // {
      //   url: '/myPage',
      //   name: "마이 페이지",
      //   slug: "myPage",
      // },
      {
        url: '/pages/logout',
        name: "로그아웃",
        slug: "logout",
      },
    ]
  },
  {
    url: null,
    name: "테스트항목",
    slug: "test",
    icon: "FileTextIcon",
    levelUp: '11',
    levelDw: '12',
    submenu: [
      {
        url: '/test/WriteArticles',
        name: "기사작성",
        slug: "testWritearticles",
      },
      {
        url: '/test/newsListAll?state=1',
        name: '뉴스리스트',
        slug: "testNewslist",
      },
      {
        url: '/test/NewsPlace?category=78',
        name: '뉴스 배치',
        slug: "testNewsplace",
      },
    ]
  },
  {
    url: "/WriteArticles",
    name: "기사작성",
    slug: "writearticles",
    icon: "CreditCardIcon",
    levelUp: '2',
    levelDw: '12',
  },
  {
    url: "/newsListDeleted",
    name: "삭제뉴스",
    slug: "newsListDeleted",
    icon: "ListIcon",
    levelUp: '10',
    levelDw: '12',
  },
  {
    url: "/newsList",
    name: "뉴스리스트",
    slug: "newslist",
    icon: "ListIcon",
    levelUp: '2',
    levelDw: '12',
    submenu: [
      {
        url: '/newsListAll?department=all',
        name: '전체',
        slug: "newslist",
      },
      {
        url: '/newsListAll?department=desk',
        name: '국장석',
        slug: "newslist_desk",
      },
      {
        url: '/newsListAll?department=g1',
        name: '정치부',
        slug: "newslist_g1",
      },
      {
        url: '/newsListAll?department=g10',
        name: '사회부',
        slug: "newslist_g10",
      },
      {
        url: '/newsListAll?department=g12',
        name: '경인본부',
        slug: "newslist_g12",
      },
      {
        url: '/newsListAll?department=g6',
        name: '정책경제부',
        slug: "newslist_g6",
      },
      {
        url: '/newsListAll?department=g2',
        name: '시장경제부',
        slug: "newslist_g2",
      },
      {
        url: '/newsListAll?department=g3',
        name: '산업부',
        slug: "newslist_g3",
      },
      {
        url: '/newsListAll?department=g11',
        name: 'ICT융합부',
        slug: "newslist_g11",
      },
      {
        url: '/newsListAll?department=g7',
        name: '생활경제부',
        slug: "newslist_g7",
      },
      {
        url: '/newsListAll?department=g5',
        name: '문화부',
        slug: "newslist_g5",
      },
      {
        url: '/newsListAll?department=g4',
        name: '스포츠부',
        slug: "newslist_g4",
      },
      {
        url: '/newsListAll?department=g13',
        name: '국제부',
        slug: "newslist_g13",
      },
      {
        url: '/newsListAll?department=g8',
        name: '사진부',
        slug: "newslist_g8",
      },
      {
        url: '/newsListAll?department=g9',
        name: '뉴미디어뉴스팀',
        slug: "newslist_g9",
      },
      {
        url: '/newsListAll?department=sns',
        name: 'SNS 전송 뉴스',
        slug: "newslist_sns",
      },
    ]
  },
  {
    url: "/photoList",
    name: "포토리스트",
    slug: "photolist",
    icon: "GridIcon",
    levelUp: '2',
    levelDw: '12',
    submenu: [
      {
        url: '/photoArticle',
        name: '기사사진',
        slug: "articlePhotos",
      },
      {
        url: '/photoDB',
        name: '포토DB',
        slug: "photosDB",
      },
    ]
  },
  {
    url: "/NewsPlace",
    name: "뉴스배치",
    slug: "newsplace",
    icon: "LayoutIcon",
    levelUp: '8',
    levelDw: '12',
    departmentEx: "g12",
    submenu: [
      // {
      //   url: '/NewsPlace?category=999',
      //   name: '심층후보 배치',
      //   slug: "newsplaceProject",
      // },
      {
        url: '/NewsPlace?category=0',
        name: '홈페이지 배치',
        slug: "newsplace",
        permission: "mainBatch"
      },
      {
        url: '/NewsPlace?category=77',
        name: '네이버 배치',
        slug: "newsplace",
        permission: "mainBatch"
      },
      {
        url: '/NewsPlace?category=78',
        name: '다음 배치',
        slug: "newsplace",
        permission: "mainBatch"
      },
      {
        url: '/NewsPlace?category=1',
        name: 'category=1',
        slug: "newsplace_1",
      },
      {
        url: '/NewsPlace?category=2',
        name: "category=2",
        slug: "newsplace_2",
      },
      // {
      //   url: '/NewsPlace?category=52',
      //   name: '2024총선 배치',
      //   slug: "newsplace_election",
      // },
      {
        url: '/NewsPlace?category=3',
        name: "category=3",
        slug: "newsplace_3",
      },
      {
        url: '/NewsPlace?category=13',
        name: "category=13",
        slug: "newsplace_13",
      },
      {
        url: '/NewsPlace?category=4',
        name: "category=4",
        slug: "newsplace_4",
      },
      {
        url: '/NewsPlace?category=5',
        name: "category=5",
        slug: "newsplace_5",
      },
      {
        url: '/NewsPlace?category=6',
        name: "category=6",
        slug: "newsplace_6",
      },
      {
        url: '/NewsPlace?category=8',
        name: "문화 배치",
        slug: "newsplace_8",
      },
      {
        url: '/NewsPlace?category=9',
        name: "category=9",
        slug: "newsplace_9",
      },
      // {
      //   url: '/NewsPlace?category=55',
      //   name: '파리올림픽 배치',
      //   slug: "newsplace_olympic",
      // },
      {
        url: '/NewsPlace?category=10',
        name: "D-Photo 배치",
        slug: "newsplace_10",
      },
      {
        url: '/NewsPlace?category=88',
        name: "D-FOCUS 배치",
        slug: "newsplace_pictorial",
      },
      // {
      //   url: '/NewsPlace?category=52',
      //   name: '2022대선 배치',
      //   slug: "newsplace_election",
      // },
      // {
      //   url: '/NewsPlace?category=51',
      //   name: '베이징 동계올림픽 배치',
      //   slug: "newsplace_olympic",
      // },
      // {
      //   url: '/NewsPlace?category=56',
      //   name: "2020총선 배치",
      //   slug: "newsplace_election",
      // },
      {
        url: '/NewsPlace?category=99',
        name: "사고, 공고 배치",
        slug: "newsplace_notice",
      },
    ]
  }, 
  {
    url: "/NewsPlace",
    name: "뉴스배치",
    slug: "newsplace",
    icon: "LayoutIcon",
    levelUp: '8',
    levelDw: '12',
    department: "g12",
    submenu: [
      {
        url: '/NewsPlace?category=13',
        name: "category=13",
        slug: "newsplace_13",
      },
    ]
  }, 
  // {
  //   url: '/NewsPlace?category=78',
  //   name: "다음 메인뉴스배치",
  //   slug: "newsplace",
  //   icon: "LayoutIcon",
  //   levelUp: '9',
  //   levelDw: '12',
  // },
  // {
  //   url: "/Calendar",
  //   name: "일정",
  //   slug: "calendar",
  //   icon: "CalendarIcon",
  //   levelUp: '2',
  //   levelDw: '9',
  // },
  {
    url: null,
    name: "게시판",
    slug: "board",
    icon: "FileTextIcon",
    levelUp: '4',
    levelDw: '12',
    submenu: [
      {
        url: '/board?tbcode=101',
        name: "현장보고",
        slug: "bd_bogo",
      },
      {
        url: '/board?tbcode=102',
        name: "정보보고",
        slug: "bd_info",
        departmentEx: "g12"
      },
      {
        url: '/board?tbcode=103',
        name: "국장석",
        slug: "bd_deskchoon",
        departmentEx: "g12"
      },
      {
        url: '/board?tbcode=105',
        name: "사내게시판",
        slug: "bd_freebd",
        departmentEx: "g12"
      },
      {
        url: '/board?tbcode=107',
        name: "제안게시판",
        slug: "bd_suggest",
        departmentEx: "g12"
      },
      {
        url: '/Overtime?boardType=1',
        name: "야근일지",
        slug: "bd_overTime",
        departmentEx: "g12"
      },
      {
        url: '/Overtime?boardType=2',
        name: "뉴미디어 당직일지",
        slug: "bd_overTime2",
        department: "g9",
        departmentEx: "g12"
      },
      {
        url: '/RequestingTimeOff',
        name: "휴가신청",
        slug: "bd_timeOff",
        departmentEx: "g12"
      },
      {
        url: '/board?tbcode=108',
        name: "제보게시판",
        slug: "bd_suggest",
      },
    ]
  },
  // {
  //   url: "/Banner",
  //   name: "배너관리",
  //   slug: "banner",
  //   icon: "ArchiveIcon",
  //   levelUp: '9',
  //   levelDw: '1',
  // },
  // {
  //   url: "/Statistics",
  //   name: "통계",
  //   slug: "statistics",
  //   icon: "PieChartIcon",
  //   levelUp: '9',
  //   levelDw: '9',
  // },
  {
    url: null,
    name: "회원관리",
    slug: "member",
    icon: "UserIcon",
    // levelUp: '9',
    levelUp: '10',
    levelDw: '12',
    submenu: [
      {
        url: '/member/common',
        name: "일반회원 관리",
        slug: "memberCommon",
      },
      {
        url: '/member/reporter?deleted=',
        name: "기자 관리",
        slug: "memberReporter",
      },
      {
        url: '/member/reporter?department=g12',
        name: "경인본부 관리",
        slug: "memberReporterg12",
      },
      {
        url: '/member/reporter?deleted=true',
        name: "퇴사자 관리",
        slug: "memberDeleted",
      },
    ]
  },
  {
    name: "통계",
    slug: "statistics",
    icon: "PieChartIcon",
    levelUp: '7',
    levelDw: '12',
    submenu: [
      {
        url: '/statistics/desc',
        name: "조회수 순",
        slug: "statisticsDesc",
      },
      {
        url: '/statistics/eachReporter',
        name: "작성자별",
        slug: "statisticsEachReporter",
      },
      {
        url: '/statistics/days',
        name: "날짜 별",
        slug: "statisticsDays",
      },
    ]
  },
  {
    url: "/timeOffDashboard",
    name: "휴가 통계",
    slug: "timeOffDashboard",
    icon: "PieChartIcon",
    levelUp: '10',
    levelDw: '12',
  },
  {
    url: "/accessDashboard",
    name: "접속 통계",
    slug: "accessDashboard",
    icon: "PieChartIcon",
    levelUp: '11',
    levelDw: '12',
  },
  {
    name: "배너",
    slug: "adBanners",
    icon: "LayoutIcon",
    levelUp: '10',
    levelDw: '12',
    submenu: [
      {
        url: '/ad/member',
        name: "광고사 관리",
        slug: "memberAd",
      },
      {
        url: '/ad/mains',
        name: "메인영역",
        slug: "adMains",
      },
      {
        url: '/ad/sides',
        name: "사이드바",
        slug: "adSides",
      },
      {
        url: '/ad/header',
        name: "상단",
        slug: "adHeader",
      },
      {
        url: '/ad/footer',
        name: "하단",
        slug: "adFooter",
      },
    ]
  },
  {
    url: "/Setting",
    name: "설정",
    slug: "setting",
    icon: "SettingsIcon",
    levelUp: '8',
    levelDw: '12',
    permission: "setting"
  },
  {
    url: '/bannerManager/main',
    name: "메인영역",
    slug: "adMains",
    icon: "LayoutIcon",
    levelUp: '1',
    levelDw: '2',
  },
  {
    url: '/bannerManager/sidebar',
    name: "사이드바",
    slug: "adSides",
    icon: "LayoutIcon",
    levelUp: '1',
    levelDw: '2',
  },
  {
    url: '/bannerManager/header',
    name: "상단",
    slug: "adHeader",
    icon: "LayoutIcon",
    levelUp: '1',
    levelDw: '2',
  },
  {
    url: '/bannerManager/footer',
    name: "하단",
    slug: "adFooter",
    icon: "LayoutIcon",
    levelUp: '1',
    levelDw: '2',
  },
  {
    url: "https://www.dailian.co.kr",
    name: "메인 웹페이지",
    slug: "external",
    target: "blank",
    icon: "HomeIcon",
    levelUp: '1',
    levelDw: '12',
  },
]
